import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";
import DetailsSaleInvoiceDialog from "./DetailsSaleInvoiceDialog";
import {downloadInvoice} from "./actions";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {saleinvoicestatusUI} from "./saleinvoicestatusUI";
import {doGet, doPostDownloadFile} from "../../../../../../Utils/Restclient/NetworkActions";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";
import SaleInvoiceAccountingentryDialog from "./SaleInvoiceAccountingentryDialog";

const getUiTab = (saleinvoiceid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Factura", component: <EditObjectDialog objectid={saleinvoiceid} entityDefinition={saleinvoiceUI}/> },
                {label : "Detalles", component: <DetailsSaleInvoiceDialog saleinvoiceid={saleinvoiceid}/>},
                {label : "Asientos", component: <SaleInvoiceAccountingentryDialog saleinvoiceid={saleinvoiceid}/>},
            ]}
    })
}

const autoComplete = (record, field, value, callback) =>{
    const valuesToChange = ['address', 'email', 'idcardnumber', 'phone', 'stateid', 'townid', 'countryid', 'paymentmethodid']
    if (field === 'customerid') {
        const newRecord = {...record};
        const customerid = record.customerid;
        valuesToChange.forEach(value => {
            newRecord[value] = customerid ? customerid[value] : null;
        })
        newRecord.name = customerid.customer
        newRecord.postalcode = customerid.postalcode
        callback && callback({...newRecord});
    } else if (field === 'contractorid') {
        const newRecord = {...record};
        const contractorid = record?.contractorid;
        newRecord.serie = contractorid?.seriesaleinvoice;
        callback && callback({...newRecord});
    }else {
        callback && callback({...record})
    }
}

const downloadPDF = (invoiceid) =>{
    downloadInvoice(invoiceid)
}

const getReportOptions = (setOption) => {
    doGet('saleinvoice/getSaleinvoiceReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    let url = 'saleinvoice/downloadSaleinvoiceReport/' + selected.saleinvoiceid;
    let fileName = op.label + '_' + selected.saleinvoiceid + '.pdf';
    showOrDownloadDocument(url, op, fileName, pdfSet)
}

export const saleinvoiceUI = {
    entity : {
        name: 'saleinvoice',
        keyField: 'saleinvoiceid',
        label: 'Factura de venta',
        colorValue: 'saleinvoicestatusid',
        //info: {typeName: 'es.rbm.model.jpa.Saleinvoice'}
    },
    uiTable: {
        fields:{
            saleinvoiceid : {name: 'saleinvoiceid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true, visible: false},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa', typeName:'es.rbm.model.jpa.Contractor'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer'},
            saleinvoicestatusid : {name: 'saleinvoicestatusid', label: 'Estado', typeName:'es.rbm.model.jpa.Saleinvoicestatus', align: 'center'},
            name : {name: 'name', label:'Nombre' , typeName: 'String', align: 'center'},
            idcardnumber : {name: 'idcardnumber', label:'NIF' , typeName: 'String', align: 'center'},
            address : {name: 'address', label:'Dirección' , typeName: 'String', align: 'center'},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', flex: 0.8, align: 'center'},
            stateid : {name: 'stateid', label:'Provinvia' , typeName: 'es.rbm.model.jpa.State', flex: 0.8, align: 'center'},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country', flex: 0.8, align: 'center'},
            postalcode : {name: 'postalcode', label:'Código postal' , typeName: 'String', align: 'center'},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', flex: 0.8, align: 'center'},
            email : {name: 'email', label:'Correo' , typeName: 'String', flex: 0.8, align: 'center'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', flex: 0.8},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', flex: 0.8},
        },
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            },
            /*downloadPDF:{
                fun: (entity) => downloadPDF(entity),
            },*/
        },
        actionFlex: 1,
        keyComponent: 'saleinvoiceCrudManagement'
    },
    uiForm : {
        fields:{
            saleinvoiceid : {name: 'saleinvoiceid', label:'Cod.' , typeName: 'Number', editable: false, size: 1, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 1},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 1, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor', size: 9},
            date : {name: 'date', label: 'Fecha',typeName:'Date', size: 3},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', size: 4.5},
            saleinvoicestatusid : {name: 'saleinvoicestatusid', label: 'Estado' ,typeName: 'es.rbm.model.jpa.Saleinvoicestatus', addObject: saleinvoicestatusUI, size: 4.5},
            name : {name: 'name', label:'Nombre' , typeName: 'String'},
            idcardnumber : {name: 'idcardnumber', label:'NIF' , typeName: 'String'},
            address : {name: 'address', label:'Dirección' , typeName: 'String'},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', flex: 0.8, align: 'center'},
            stateid : {name: 'stateid', label:'Provinvia' , typeName: 'es.rbm.model.jpa.State', flex: 0.8, align: 'center'},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country', flex: 0.8, align: 'center'},
            postalcode : {name: 'postalcode', label:'Código postal' , typeName: 'String'},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', flex: 0.8},
            email : {name: 'email', label:'Correo' , typeName: 'String', flex: 0.8},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', flex: 0.8},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', flex: 0.8, optional: true,size: 12 , multiline: true, rows: 3},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        initialsValues: {
            date_1: new Date(new Date().setDate(new Date().getDate() - 7))
        },
        fields: {
            //saleinvoiceid : {name: 'saleinvoiceid', label: 'Nº factura' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL},
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            date_1 : {name: 'date_1',label: 'Desde', typeName: 'Date', operator: OPERATORS.OPERATOR_GTE, empty: true, optional: true},
            date_2 : {name: 'date_2',label: 'Hasta', typeName: 'Date', operator: OPERATORS.OPERATOR_LTE, empty: true, endDay: true, optional: true},
            saleinvoicestatusid : {name: 'saleinvoicestatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Saleinvoicestatus', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            name : {name: 'name',label: 'Nombre', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            idcardnumber : {name: 'idcardnumber',label: 'NIF', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            email : {name: 'email',label: 'Telefono', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    uiTab: (saleinvoice) => getUiTab(saleinvoice)
}

