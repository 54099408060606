import uiDefinition from "./uiDefinition";
import {doPostDownloadFile, doPostPDF} from "./Restclient/NetworkActions";

export const generateRandomKey = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const groupBy = (items, key) => items.reduce(
    (result, item) => {
        const newKey = key.split('.').reduce((l, c) => {
                return l[c] || ''},
            item);
        return {
            ...result,
            [newKey]: [
                ...(result[newKey] || []),
                item,
            ],
        }
    },
    {});

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

export const getEntityDescription = (entity, clazz, hideId, keyField, descriptiveField) => {
    const uiDefinitionElement = uiDefinition[clazz];
    if (entity && uiDefinitionElement){
        const key = keyField || uiDefinitionElement.keyField;
        const mainDescriptiveField = descriptiveField || uiDefinitionElement.descriptiveField;
        let keyDescription = hideId ? '' : '(' + entity[key] + ') ';
        let descriptiveDescription = mainDescriptiveField.split('.').reduce((l, c) => { return l + entity[c] + " "}, '');
        return keyDescription + descriptiveDescription.trim()
    } else {
        return '';
    }
}

export const notEditableFields = (ui) => {
    const newUI = {...ui};
    const {fields} = newUI;
    Object.keys(fields).forEach(key => {
        fields[key] = {...fields[key], editable: false}
    })
    newUI.fields = fields;
    return newUI;
}

export const filterRecordFromText = (filterValue, records, keysToFilter) => {
    const isValid = (record, columns, filterValue) => {
        const filtered = columns.filter(c => {
            const recordText = (record[c] + '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            return recordText.includes(filterValue);
        });
        return filtered.length;
    }
    const normalizeValue = filterValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return records.filter(r => isValid(r, keysToFilter, normalizeValue));
}

export const showOrDownloadDocument = (url, request, fileName, pdfSet) => {
    if (pdfSet && fileName.toLowerCase().endsWith('.pdf')) {
        doPostPDF(url, request, result => pdfSet(result));
    } else {
        doPostDownloadFile(url, request, fileName);
    }
}