import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doDelete} from "../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../View/Form/GridTable";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import Typography from "@mui/material/Typography";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

const ui = {
    fields: {
        accountingentrydetailid : {name: 'accountingentrydetailid', label: '#',typeName:'String', flex: 0.3, align: 'center', optional: false, visible: false},
        accountingaccountid : {name: 'accountingaccountid', label:'Cuenta' , typeName: 'es.rbm.model.jpa.Accountingaccount', keyField: 'account', descriptiveField: 'description', flex: 1.5, optional: false},
        concept : {name: 'concept', label: 'Concepto',typeName:'String', align: 'center', optional: false, flex: 1.5},
        debit : {name: 'debit', label:'Debe' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter},
        credit : {name: 'credit', label:'Haber' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter},
    },
    sort: {
        field: 'accountingentrydetailid',
        sort: 'desc'
    },
    keyComponent: 'saleAccountingentrydetails',
}

export default function SaleInvoiceAccountingentryDialog(props) {
    const {saleinvoiceid} = props;
    const [records, setRecords] = React.useState([]);
    const [balance, setBalance] = React.useState(0);

    useEffect(() =>{
        refresh()
    }, [])

    const refresh = () => {
        setRecords([])
        const filters = {
            entity : {name: 'accountingentrydetail'},
            fields: {
                accountingentryid : {
                    name: 'accountingentryid', 
                    operator: OPERATORS.OPERATOR_EQUAL, 
                    value: saleinvoiceid.accountingentryid
                }
            },
        }
        searchByCriteria(filters, result =>{
            if (result) {
                let totalDebit = result.reduce((l, c) => l + c.debit, 0);
                let totalCredit = result.reduce((l, c) => l + c.credit, 0);
                setBalance(totalCredit - totalDebit)
                setRecords(result)
            }
        });
    }
    
    const onDelete = (accountingentrydetailid) => {
        doDelete('rest/accountingentrydetail/delete/' + accountingentrydetailid.accountingentrydetailid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const record = records.filter(r => r.accountingentrydetailid === e.id);
        if (record.length) {
            record[0][e.field] = e.value;
            doPutRest('accountingentrydetail', record[0], () => refresh());
        } else {
            doPutRest('accountingentrydetail', e, () => refresh());
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center', width: '100%' }} align={'center'}>
            <Typography component="h1" variant="h6" color={'#ec0e0e'} align={'center'}>
                {balance !== 0 ? 'El asiento está descuadrado' : ''}
            </Typography>
            <GridTable ui={ui} onDelete={onDelete}
                       records={records} rowId={'accountingentrydetailid'}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '20vh'}}
            />
        </Box>
    )
}
