import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {defaultSendMail} from "../../../../../../View/Form/utils";
import SaleorderCustomerView from "./SaleorderCustomerView";
import SalenoteCustomerView from "./SalenoteCustomerView";
import CustomerproductView from "./CustomerproductView";
import AttributeCustomerDialog from "./AttributeCustomerDialog";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {townUI} from "../Other/townCrud";
import {stateUI} from "../Other/stateCrud";
import {countryUI} from "../Other/countryCrud";

const getUiTab = (customerid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Cliente", component: <EditObjectDialog objectid={customerid} entityDefinition={customerUI}/> },
                {label : "Atributos", component: <AttributeCustomerDialog customerid={customerid}/> },
                {label : "Tarifas", component: <CustomerproductView customerid={customerid}/> },
                {label : "Pedidos", component: <SaleorderCustomerView customerid={customerid}/>},
                {label : "Albaranes", component: <SalenoteCustomerView customerid={customerid}/> },
                {label : "Facturas", component: null, disabled: true },
            ]}
    })
}

export const customerUI = {
    entity : {
        name: 'customer',
        keyField: 'customerid',
        label: 'Cliente',
        urlExcelImport: 'importer/importCustomerFromExcel',
        urlDownloadTemplate: 'importer/getCustomerTemplate',
        info: {typeName: 'es.rbm.model.jpa.Customer'}
    },
    uiTable: {
        fields:{
            customerid : {name: 'customerid', label:'#' , typeName: 'Number', editable: false, flex: 0.3},
            customer : {name: 'customer', label:'Nombre' , typeName: 'String', editable: true, flex: 1.5},
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true, align: 'center'},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', editable: true, align: 'center'},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, align: 'center'},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'Number', editable: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, align: 'center'},
            accountingaccountid : {name: 'accountingaccountid',label: 'Cuenta contable', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
        },
        keyComponent: 'customerCrudManagement',
        actions: {
            sendEmail: {
                fun: (entity ,setOpenMail, setMailRecords) => defaultSendMail(entity,'email',setOpenMail, setMailRecords)
            },
        },
    },
    uiForm : {
        fields:{
            customerid : {name: 'customerid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
            customer : {name: 'customer', label:'Nombre' , typeName: 'String' },
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            email : {name: 'email', label: 'Correo' , typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label: 'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label: 'Población' , typeName: 'es.rbm.model.jpa.Town', editable: true, addObject: townUI},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, addObject: stateUI},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'Number', editable: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, addObject: countryUI},
            accountingaccountid : {name: 'accountingaccountid',label: 'Cuenta contable', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true}
        }
    },
    filters : {
        startBegin : true,
        fields: {
            customer : {name: 'customer', label: 'Cliente' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI/CIF' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town',  operator: OPERATORS.OPERATOR_LIKE, optional: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State',  operator: OPERATORS.OPERATOR_LIKE, optional: true},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country',  operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    uiTab: (customer) => getUiTab(customer)
}

