 const uiDefinition = {
     'ObjectRender' : {
        keyField: 'id',
        descriptiveField: 'value'
     },
     'es.rbm.model.jpa.Absence' : {
        keyField: 'absenceid',
         descriptiveField: 'absence',
         url: 'rest/absence/getAll',
         entityName: 'absence'
     },
     'es.rbm.model.jpa.Absencetype' : {
        keyField: 'absencetypeid',
         descriptiveField: 'absencetype',
         url: 'rest/absencetype/getAll',
         entityName: 'absencetype'
     },
     'es.rbm.model.jpa.Account' : {
        keyField: 'accountid',
         descriptiveField: 'login',
         url: 'rest/account/getAll',
         entityName: 'account'
     },
     'es.rbm.model.jpa.Accountingaccount' : {
         keyField: 'accountingaccountid',
         descriptiveField: 'account.description',
         url: 'rest/accountingaccount/getAll',
         entityName: 'accountingaccount'
     },
     'es.rbm.model.jpa.Agrodataperiod' : {
        keyField: 'dataperiodid',
         descriptiveField: 'dataperiodid',
         url: 'rest/agrodataperiod/getAll',
         entityName: 'agrodataperiod'
     },
     'es.rbm.model.jpa.Agrodataperioddetail' : {
        keyField: 'dataperioddetailid',
         descriptiveField: 'dataperioddetailid',
         url: 'rest/agrodataperioddetail/getAll',
         entityName: 'agrodataperioddetail'
    },
     'es.rbm.model.jpa.Aludataperiod' : {
         keyField: 'dataperiodid',
         descriptiveField: 'dataperiod',
         url: 'rest/aludataperiod/getAll',
         entityName: 'aludataperiod'
     },
     'es.rbm.model.jpa.Aludataperioddetail' : {
         keyField: 'dataperioddetailid',
         descriptiveField: 'dataperioddetailid',
         url: 'rest/aludataperioddetail/getAll',
         entityName: 'aludataperioddetail'
     },
     'es.rbm.model.jpa.Alusaleorder' : {
         keyField: 'saleorderid',
         descriptiveField: 'serie.number',
         url: 'rest/alusaleorder/getAll',
         entityName: 'alusaleorder'
     },
     'es.rbm.model.jpa.Batch' : {
        keyField: 'batchid',
         descriptiveField: 'batch',
         url: 'rest/batch/getAll',
         entityName: 'batch'
    },
     'es.rbm.model.jpa.Campaign' : {
        keyField: 'campaignid',
        descriptiveField: 'campaign',
        url: 'rest/campaign/getAll',
         entityName: 'campaign'
    },
     'es.rbm.model.jpa.Category' : {
        keyField: 'categoryid',
        descriptiveField: 'category',
        url: 'rest/category/getAll',
         entityName: 'category'
    },
     'es.rbm.model.jpa.Customer' : {
        keyField: 'customerid',
        descriptiveField: 'customer',
        url: 'rest/customer/getAll',
         entityName: 'customer'
    },
     'es.rbm.model.jpa.Contractor' : {
        keyField: 'contractorid',
        descriptiveField: 'contractor',
        url: 'rest/contractor/getAll',
         entityName: 'contractor'
    },
     'es.rbm.model.jpa.Construction' : {
        keyField: 'constructionid',
        descriptiveField: 'construction',
        url: 'rest/construction/getAll',
         entityName: 'construction'
    },
     'es.rbm.model.jpa.Country' : {
        keyField: 'countryid',
        descriptiveField: 'country',
        url: 'rest/country/getAll',
         entityName: 'country'
    }
    , 'es.rbm.model.jpa.Cultivation' : {
        keyField: 'cultivationid',
        descriptiveField: 'cultivation',
        url: 'rest/cultivation/getAll',
         entityName: 'cultivation'
    }
    ,'es.rbm.model.jpa.Color' : {
        keyField: 'colorid',
        descriptiveField: 'color',
        url: 'rest/color/getAll',
         entityName: 'color'
    },'es.rbm.model.jpa.Dataperiod' : {
        keyField: 'dataperiodid',
        descriptiveField: 'dataperiod',
        url: 'rest/dataperiod/getAll',
         entityName: 'dataperiod'
    },'es.rbm.model.jpa.Dataperioddetail' : {
        keyField: 'dataperioddetailid',
        descriptiveField: 'dataperioddetailid.date',
        url: 'rest/dataperioddetail/getAll',
         entityName: 'dataperioddetail'
    },
     'es.rbm.model.jpa.Fertigation' : {
         keyField: 'fertigationid',
         descriptiveField: 'fertigation',
         url: 'rest/treatment/getAll',
         entityName: 'fertigation'
     },
     'es.rbm.model.jpa.Harvest' : {
         keyField: 'harvestid',
         descriptiveField: 'harvestid',
         url: 'rest/harvest/getAll',
         entityName: 'harvest'
     },
     'es.rbm.model.jpa.House' : {
         keyField: 'houseid',
         descriptiveField: 'house',
         url: 'rest/house/getAll',
         entityName: 'house'
     },
     'es.rbm.model.jpa.Movementunit' : {
         keyField: 'movementunitid',
         descriptiveField: 'movementunit',
         url: 'rest/movementunit/getAll',
         entityName: 'movementunit'
     },
    'es.rbm.model.jpa.Paymentmethod' : {
        keyField: 'paymentmethodid',
        descriptiveField: 'paymentmethod',
        url: 'rest/paymentmethod/getAll',
        entityName: 'paymentmethod'
    },
     'es.rbm.model.jpa.Plot' : {
        keyField: 'plotid',
        descriptiveField: 'plot',
        url: 'rest/plot/getAll',
        entityName: 'plot'
    },
    'es.rbm.model.jpa.Product' : {
        keyField: 'productid',
        descriptiveField: 'product',
        url: 'rest/product/getAll',
        entityName: 'product'
    },
     'es.rbm.model.jpa.Producttype' : {
         keyField: 'producttypeid',
         descriptiveField: 'producttype',
         url: 'rest/producttype/getAll',
         entityName: 'producttype'
     },
     'es.rbm.model.jpa.Provider' : {
         keyField: 'providerid',
         descriptiveField: 'provider',
         url: 'rest/provider/getAll',
         entityName: 'provider'
     },
     'es.rbm.model.jpa.Purchaseinvoice' : {
         keyField: 'purchaseinvoiceid',
         descriptiveField: 'serie.number',
         url: 'rest/purchaseinvoice/getAll',
         entityName: 'purchaseinvoice'
     },
     'es.rbm.model.jpa.Purchaseinvoicestatus' : {
         keyField: 'purchaseinvoicestatusid',
         descriptiveField: 'purchaseinvoicestatus',
         url: 'rest/purchaseinvoicestatus/getAll',
         entityName: 'purchaseinvoicestatus'
     },
     'es.rbm.model.jpa.Purchasenote' : {
         keyField: 'purchasenoteid',
         descriptiveField: 'serie.number',
         url: 'rest/purchase/getAll',
         entityName: 'purchase'
     },
     'es.rbm.model.jpa.Purchaseorder' : {
         keyField: 'purchaseorderid',
         descriptiveField: 'serie.number',
         url: 'rest/purchaseorder/getAll',
         entityName: 'purchaseorder'
     },
     'es.rbm.model.jpa.Purchaseorderstatus' : {
         keyField: 'purchaseorderstatusid',
         descriptiveField: 'purchaseorderstatus',
         url: 'rest/purchaseorderstatus/getAll',
         entityName: 'purchaseorderstatus'
     },
     'es.rbm.model.jpa.Reasonuse' : {
         keyField: 'reasonuseid',
         descriptiveField: 'reasonuse',
         url: 'rest/reasonuse/getAll',
         entityName: 'reasonuse'
     },
     'es.rbm.model.jpa.Recipe' : {
         keyField: 'recipeid',
         descriptiveField: 'recipe',
         url: 'rest/recipe/getAll',
         entityName: 'recipe'
     },
     'es.rbm.model.jpa.Saleinvoice' : {
         keyField: 'saleinvoiceid',
         descriptiveField: 'serie.number',
         url: 'rest/saleinvoice/getAll',
         entityName: 'saleinvoice'
     },
     'es.rbm.model.jpa.Saleinvoicestatus' : {
         keyField: 'saleinvoicestatusid',
         descriptiveField: 'saleinvoicestatus',
         url: 'rest/saleinvoicestatus/getAll',
         entityName: 'saleinvoicestatus'
     },
     'es.rbm.model.jpa.Saleorder' : {
         keyField: 'saleorderid',
         descriptiveField: 'serie.number',
         url: 'rest/saleorder/getAll',
         entityName: 'saleorder'
     },
     'es.rbm.model.jpa.Saleorderstatus' : {
         keyField: 'saleorderstatusid',
         descriptiveField: 'saleorderstatus',
         url: 'rest/saleorderstatus/getAll',
         entityName: 'saleorderstatus'
     },
     'es.rbm.model.jpa.Salenote' : {
         keyField: 'salenoteid',
         descriptiveField: 'serie.number',
         url: 'rest/salenote/getAll',
         entityName: 'salenote'
     },
     'es.rbm.model.jpa.State' : {
         keyField: 'stateid',
         descriptiveField: 'state',
         url: 'rest/state/getAll',
         entityName: 'state'
     },
     'es.rbm.model.jpa.Tank' : {
         keyField: 'tankid',
         descriptiveField: 'tank',
         url: 'rest/tank/getAll',
         entityName: 'tank'
     },
     'es.rbm.model.jpa.Task' : {
         keyField: 'taskid',
         descriptiveField: 'task',
         url: 'rest/task/getAll',
         entityName: 'task'
     },
     'es.rbm.model.jpa.Tasktype' : {
         keyField: 'tasktypeid',
         descriptiveField: 'tasktype',
         url: 'rest/tasktype/getAll',
         entityName: 'tasktype'
     },
     'es.rbm.model.jpa.Tractor' : {
         keyField: 'tractorid',
         descriptiveField: 'tractor',
         url: 'rest/tractor/getAll',
         entityName: 'tractor'
     },
     'es.rbm.model.jpa.Treatment' : {
         keyField: 'treatmentid',
         descriptiveField: 'treatment',
         url: 'rest/treatment/getAll',
         entityName: 'treatment'
     },
     'es.rbm.model.jpa.Town' : {
         keyField: 'townid',
         descriptiveField: 'town',
         url: 'rest/town/getAll',
         entityName: 'town'
     },
    'es.rbm.model.jpa.Vat' : {
        keyField: 'vatid',
        descriptiveField: 'vat',
        url: 'rest/vat/getAll',
        entityName: 'vat'
    },
     'es.rbm.model.jpa.Vehicle' : {
         keyField: 'vehicleid',
         descriptiveField: 'vehicle',
         url: 'rest/vehicle/getAll',
         entityName: 'vehicle'
     },
     'es.rbm.model.jpa.Vehiclebrand' : {
        keyField: 'vehiclebrandid',
        descriptiveField: 'vehiclebrand',
        url: 'rest/vehiclebrand/getAll',
        entityName: 'vehiclebrand'
    },
     'es.rbm.model.jpa.Vehiclecardcode' : {
        keyField: 'vehiclecardcodeid',
        descriptiveField: 'vehiclecardcode',
        url: 'rest/vehiclecardcode/getAll',
        entityName: 'vehiclecardcode'
    },
     'es.rbm.model.jpa.Vehicleoperation' : {
        keyField: 'vehicleoperationid',
        descriptiveField: 'vehicleoperation',
        url: 'rest/vehicleoperation/getAll',
        entityName: 'vehicleoperation'
    },
     'es.rbm.model.jpa.Vehicleoperationtype' : {
        keyField: 'vehicleoperationtypeid',
        descriptiveField: 'vehicleoperationtype',
        url: 'rest/vehicleoperationtype/getAll',
        entityName: 'vehicleoperationtype'
    },
     'es.rbm.model.jpa.Warehouse' : {
        keyField: 'warehouseid',
        descriptiveField: 'warehouse',
        url: 'rest/warehouse/getAll',
         entityName: 'warehouse'
    },
     'es.rbm.model.jpa.Worker' : {
        keyField: 'workerid',
         descriptiveField: 'name.surname',
         url: 'rest/worker/getAll',
         entityName: 'worker'
    },
     'es.rbm.model.jpa.Workspace' : {
        keyField: 'workspaceid',
         descriptiveField: 'workspace',
         url: 'rest/workspace/getAll',
         entityName: 'workspace'
    },
    'recipetype' : {
        url: 'agro/getAllsRecipetypes'
    },
}

export default uiDefinition;