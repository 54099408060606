import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {alpha, Divider, InputBase, LinearProgress} from "@mui/material";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import {doGet, doPost} from "../Utils/Restclient/NetworkActions";
import {getToken, serverName} from "../Utils/Constant";
import {useStateValue} from "../Utils/Redux/StateProvider";
import {actionTypes} from "../Utils/Redux/reducer";
import store from "../Utils/Redux/store";
import Grid from "@mui/material/Grid";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PropTypes from "prop-types";
import HelpIcon from '@mui/icons-material/Help';
import ListItemIcon from "@mui/material/ListItemIcon";
import GroupIcon from '@mui/icons-material/Group';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {ROLES} from "../roles";
import {styled, useTheme} from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import {SOLUTIONS} from "../solutions";

const ALLS_USERS = 'ALLS_USERS';

const settingsMenu = {
    notUser: [
        /*{   title: 'Entrar',
            destination: "/login",
            color: 'black',
            action: null,
            icon: <LoginIcon />,
            roles: [ALLS_USERS]
        },*/
        {   title: 'Contactar',
            destination: "/",
            color: 'black',
            action: null,
            icon: <ContactSupportIcon />,
            roles: [ALLS_USERS]
        },
    ],

    user: [
        {   title: 'Inicio',
            destination: "/main",
            action: null,
            color: 'black',
            roles: [ALLS_USERS],
            icon: <HomeIcon fontSize="small"/>,
        },
        /*{   title: 'Perfil',
            destination: "/profile",
            action: null,
            color: 'black',
            roles: [ALLS_USERS],
            icon: <PersonIcon/>,
        },*/
        {   title: 'Usuarios',
            destination: "/users",
            action: null,
            color: 'black',
            roles: [ROLES.ROLE_ADMIN],
            solutions: [SOLUTIONS.SOLUTION_AGRO, SOLUTIONS.SOLUTION_DEFAULT, SOLUTIONS.SOLUTION_DEFAULT],
            icon: <GroupIcon/>
        },
        {   title: 'Ayuda',
            destination: null,
            action: () => window.open(process.env.REACT_APP_WEB_PROTOCOL + '://' + serverName + '/help/', '_blank'),
            color: 'black',
            roles: [ALLS_USERS],
            icon: <HelpIcon fontSize="small"/>
        },
        {   title: 'Salir',
            destination: "/",
            action: "Logout",
            color: 'red',
            roles: [ALLS_USERS],
            icon: <ExitToAppIcon/>
        }
    ]
}

const CustomAvatar = (props) => {
    const {state, handleOpenUserMenu, account} = props;

    useEffect(() => {}, [props]);

    return(
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={state.avatar ? account?.login?.substring(0,1)?.toUpperCase() : ""} src={state.token ? "/static/images/avatar/2.jpg" : null} />
        </IconButton>
    )
}

CustomAvatar.propTypes = {
    state: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    handleOpenUserMenu: PropTypes.func.isRequired,
};

const LabelText = (props) => {
    const {account, state} = props;

    useEffect(() => {}, [props]);

    return(
        (account && state.token && state.avatar) &&
        <Typography sx={{mr: 2}} textAlign="center">
            <strong>{account?.login?.toUpperCase()}</strong>
        </Typography>
    )
}

LabelText.propTypes = {
    state: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    handleOpenUserMenu: PropTypes.func.isRequired,
};

const NavBar = () => {
    const [{menuVisibility}, dispatch] = useStateValue();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [account, setAccount] = React.useState(null);
    const [companyName, setCompanyName] = React.useState('');
    const [state, setState] = React.useState({menu : (getToken() ? settingsMenu.user : settingsMenu.notUser), token: getToken(), waiting: false, avatar: false});
    const theme = useTheme();

    store.subscribe(() => {
        const change = store.getState();
        const localToken = getToken();
        const isUserLoad = change.token?.length > 0 || localToken?.length > 0;
        const newMenu = isUserLoad ? settingsMenu.user : settingsMenu.notUser;
        const newToken = isUserLoad ? localToken : null ;
        const newState = {menu: newMenu, token: newToken, waiting: change?.active_network, avatar: isUserLoad}
        setState({...state, ...newState})
    })

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const Logout = () =>{
        localStorage.removeItem("token-session-erp");
        setState({...state, token: null, menu: settingsMenu.notUser})
        dispatch({type: actionTypes.SET_USER, token: null})
    }

    const changeMenu = () =>{
        dispatch({type: actionTypes.VISIBLE_MENU, value: !menuVisibility})
    }

    const handleSelectUserMenu = (index, setting) => {
        if (setting.action) {
            if(setting.action === "Logout"){
                Logout();
            } else {
                setting.action();
            }
        }
        setAnchorElUser(null);
    };

    function filterMenu(newState) {
        doGet('auth/getRoles', roles => {
             const newMenu = settingsMenu.user.filter(menuEntry => {
                if (menuEntry.roles.length && !menuEntry.roles.includes(ALLS_USERS)) {
                    return menuEntry.roles.some(e => roles.includes(e));
                }
                return true;
            });
            setState({...newState, menu: newMenu})
        })
    }

    useEffect(() =>{
        if (state.token) {
            doGet('auth/getProfile', result =>{
                setAccount(result);
                filterMenu({...state, avatar: true});
            })

            doGet('configuration/getAttributeConfig/Nombre servidor', result => {
                setCompanyName(result.value || '');
            })
        }
    },[state.token])

    return (
        <Box>
            <AppBar position={"fixed"} style={{
                background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                color: '#ffffff',
                boxShadow: 'inset 0 0 20px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1)',

            }}>
                <Grid container>
                    <Grid item md={0.5}  sx={{ mt: 0 }} justifyContent={'center'} alignItems={'center'} alignContent={'center'} textAlign={'center'}>
                        <Tooltip title="Abrir/cerrar menú">
                            <IconButton onClick={changeMenu} color={'inherit'}  sx={{ p: 0 }}>
                                <MenuIcon  fontSize={'large'}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item md={4.5}  sx={{ mt: 0 }} justifyContent={'center'} alignItems={'center'} alignContent={'center'} textAlign={'center'}>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            sx={{mr: 2, display: { xs: 'none', md: 'flex' }, fontFamily: 'monospace', fontWeight: 400, color: 'white', textDecoration: 'none'}}>
                            {companyName}
                        </Typography>
                    </Grid>
                    <Grid item md={7} sx={{pr: 2}}>
                            <Toolbar disableGutters>
                                <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' } }}/>
                                <LabelText account={account} state={state}/>
                                <Box sx={{flexGrow: 0}}>
                                    <Tooltip title="Abrir ajustes">
                                        <CustomAvatar account={account} state={state} handleOpenUserMenu={handleOpenUserMenu}/>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {state.menu?.map((setting, i) => (
                                            <Link key={i} to={setting.destination} style={{ textDecoration: 'none', color: 'black' }}>
                                                <MenuItem key={i} onClick={() => handleSelectUserMenu(i, setting)}>
                                                    {setting.icon && <ListItemIcon> {setting.icon} </ListItemIcon>}
                                                    <Typography sx={{mr: 3}} textAlign="center">{setting.title}</Typography>
                                                </MenuItem>
                                                { i !== (state.menu.length - 1) && <Divider/> }
                                            </Link>
                                        ))}
                                    </Menu>
                                </Box>
                            </Toolbar>
                    </Grid>
                    {state.waiting && <Grid item md={12}>
                         <LinearProgress color="secondary" key={'mainLinearProgress'}/>
                    </Grid> }
                </Grid>
            </AppBar>
        </Box>


    );
};
export default NavBar;
