import {doDelete, doPost, doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

export const updatePurchaseorderdetail = (purchaseorderdetailid, callback) =>{
    doPut('rest/purchaseorderdetail/update',purchaseorderdetailid, response =>{
        if(response){
            callback && callback();
        }
    })
}

export const deletePurchaseorderdetail = (purchaseorderdetailid, callback) =>{
    doDelete('rest/purchaseorderdetail/delete/' + purchaseorderdetailid.purchaseorderdetailid,null, response =>{
        callback && callback();
    })
}

export const getDetails = (purchaseorderid, callback) =>{
    doPost('purchaseorder/getDetails',purchaseorderid, result => callback && callback(result))
}

export const getTotalAmount = (purchaseorderdetailid) =>{
    const totalpurchaseorderdetailprice = purchaseorderdetailid.price * purchaseorderdetailid.quantity;
    if(purchaseorderdetailid.discount){
        return Math.floor((((totalpurchaseorderdetailprice/100)*(100-purchaseorderdetailid.discount)) *100))/100;
    }
    return (totalpurchaseorderdetailprice * 100) /100;
}

export const getProviderproductprice = (record, field, value, callback) =>{
    const fieldstoChange = ['quantity', 'productid']
    if(fieldstoChange.includes(field)){
        if(record.purchaseorderid.providerid && record.quantity && record.productid){
            const filters = {
                entity : {name: 'providerproduct'},
                fields: {
                    providerid : {name: 'providerid', operator: OPERATORS.OPERATOR_EQUAL, value: record.purchaseorderid.providerid},
                    productid : {name: 'productid', operator: OPERATORS.OPERATOR_EQUAL, value: record.productid},
                    fromquantity : {name: 'fromquantity', operator: OPERATORS.OPERATOR_LTE, value: record.quantity},
                    toquantity : {name: 'toquantity', operator: OPERATORS.OPERATOR_GTE, value: record.quantity},
                    since : {name: 'since', operator: OPERATORS.OPERATOR_LTE, value: record.purchaseorderid.date},
                    until : {name: 'until', operator: OPERATORS.OPERATOR_GTE, value: record.purchaseorderid.date},
                },
            }
            searchByCriteria(filters, result =>{
                if(result && result.length > 0){
                    callback && callback({...record, price: result[0].price})
                } else {
                    callback && callback({...record, price: record.productid.purchasecost || 0})
                }
            });
        }
    }
    callback && callback(record);
}

export const getPurchaseinvoicedetails = (purchaseinvoiceid, callback) => {
    const filters = {
        entity : {name: 'purchaseinvoicedetail'},
        fields: {purchaseinvoiceid : {name: 'purchaseinvoiceid', operator: 'OPERATOR_EQUAL', value: purchaseinvoiceid}},
    }
    searchByCriteria(filters, result => callback && callback(result));
}

export const filterNotes = (records, callback) => {
    const purchaseinvoiceid = records.purchaseinvoiceid;
    if (purchaseinvoiceid) {
        const filters = {
            entity : {name: 'purchasenote'},
            fields: {
                purchaseinvoiceid : {name: 'purchaseinvoiceid', operator: OPERATORS.OPERATOR_ISNULL},
                providerid : {name: 'providerid', operator: OPERATORS.OPERATOR_EQUAL, value: purchaseinvoiceid.providerid},
            },
        }
        searchByCriteria(filters, result =>{
            callback && callback(result);
        });
    } else {
        callback && callback([])
    }
}

export const getPurchasenoteDetails = (purchasenoteid, callback) =>{
    doPost('purchasenote/getDetails',purchasenoteid, result => callback && callback(result))
}

export const addPurchasenote = (purchaseinvoice, purchasenote, callback) => {
    const toSend = {purchasenoteid: purchasenote, purchaseinvoiceid: purchaseinvoice}
    doPost('purchaseinvoice/generateInvoiceFromNote', toSend, callback);
}